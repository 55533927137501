@import "../../../lib/scss/mixins";

.FloorsHeader {
  .PageHeader {
    padding-bottom: toRem(16px);

    @include mobile-tablet {
      padding-bottom: toRem(25px);
    }
  }

  .StructureHeader {
    &__title {
      h1 {
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    @include mobile {
      max-width: 80%;
    }

    @media (max-width: 540px) {
      max-width: 60%;
    }
  }

  &__buttons {
    display: flex;
    grid-gap: toRem(20px);
    margin-left: toRem(16px);

    .UploadImage {
      .Button .Icon path {
        fill: unset;
      }
    }

    &--settings {
      .Icon {
        left: toRem(12px);

        svg {
          path {
            fill: #000 !important;
          }
        }

        @include mobile-tablet {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      @include mobile-tablet {
        right: toRem(55px) !important;
      }
    }

    &--counter {
      display: none;
      @include mobile-tablet {
        display: inline !important;
        margin-right: toRem(4px);
        font-size: toRem(13px);
      }
    }

    @include mobile-tablet {
      .FloorPickerButton {
        display: none;
      }
    }
  }

  &__pusher {
    position: absolute;
    bottom: toRem(10px);
    cursor: pointer;
    span {
      color: #808080;
      font-weight: 400;
      margin-right: toRem(5px);
    }

    .push-icon {
      svg {
        width: toRem(20px);
      }
    }
  }
}
